<template>
    <div class="w-100">
        <div v-if="channels.length">
            <div class="w-100" v-for="channel in channels" :key="channel.id">
                <div class="bg-white rounded p-4 mt-4 d-flex justify-content-between" v-b-toggle="`accordion-imported${channel._id}`">
                    <span class="d-flex align-items-center">
                        <div class="text-secondary me-3">#{{channel.name}}</div>
                        <h5 class="my-0 me-3">{{channel.channelNumber}}</h5>
                    </span>
                    <span class="d-flex align-items-center">
                        <b-icon font-scale="1.25" icon="chevron-down" role="button"></b-icon>
                    </span>
                </div>
                
                <b-collapse class="bg-white p-4 text-center" :id="`accordion-imported${channel._id}`" :ref="`accordion-imported${channel._id}`" accordion="my-accordion">
                    <b-button variant="primary" class="rounded" @click="getQr(channel)" v-if="!channel.loading">Solicitar QR Code</b-button>
                    <div v-else>
                        <qrcode :value="channel.qr" :options="{ width: 200 }" v-if="channel.qr"></qrcode>
                        <b-spinner v-else></b-spinner>
                    </div>
                </b-collapse>
            </div>
        </div>
        <div class="bg-white text-center p-3 text-secondary rounded" v-else>
            Nenhum canal para ler QR!
        </div>
    </div>
</template>

<script>
import api from '../services/apiService.js'

export default {
    props:['socket'],
    mounted: function(){
        this.loadGotQr()
        this.socket.on('qr',async (data)=>{
            // console.log('qr',data)
            const channel = this.channels.find(e => e._id == data.id)
            if(channel) {
                if(this.$refs[`accordion-imported${channel._id}`] && this.$refs[`accordion-imported${channel._id}`][0] && !this.$refs[`accordion-imported${channel._id}`][0].show) {
                    this.$refs[`accordion-imported${channel._id}`][0].toggle()
                }
                this.$set(channel, 'qr', data.qr)
            } else {
                console.log('channel not found')
            }
            
        })
        this.socket.on('authenticated',async (channelId) => {
            console.log('authenticated',channelId)
            this.channels = this.channels.filter(el=>el._id!=channelId)
        })
    },
    methods:{
        async loadGotQr(){
            const resp = await api.getChannelsGotQr()
            console.log('resp channels qr',resp)
            this.channels = resp.channels.sort((a,b) => a._id - b._id)
        },
        getQr(channel){
            channel.id = channel._id
            channel.whatsapp_id = channel.channelNumber
            this.socket.emit('get_qr',channel)
            console.log('emit get_qr')
            this.$set(channel, 'loading', true)
        },
    },
    data(){
        return{
            channels: [],
            serversIds: [],
        }
    },
}
</script>

<style scoped>

</style>